<section id="skills">
  <div class="container">
    <h2>
      <strong>{{ "skills.title" | translate }}</strong>
    </h2>
    <span class="bar-title mb-5"></span>

    <div class="text-center tags mb-5">
      <span
        *ngFor="let tag of skills.managementTags"
        class="m-1 badge-success badge ng-star-inserted"
      >
        {{ tag | titlecase }}
      </span>
      <span
        *ngFor="let tag of skills.secTags"
        class="m-1 badge-info badge ng-star-inserted"
      >
        {{ tag | titlecase }}
      </span>
      <span
        *ngFor="let tag of skills.devTags"
        class="m-1 badge-warning badge ng-star-inserted"
      >
        {{ tag | titlecase }}
      </span>
    </div>

    <div class="row mt-2">
      <div
        class="col-lg-4"
        data-aos="fade-up "
        data-aos-anchor-placement="center-bottom"
        data-aos-delay="300"
      >
        <h5 class="h5">
          <strong>{{ "skills.technologies" | translate }}</strong>
        </h5>
        <div id="skillgraph">
          <div
            class="row skill-row"
            *ngFor="let technologie of skills.technologies"
          >
            <div class="label d-flex flex-row">
              <span class="skillLabel">{{ technologie.name }}</span>
              <span class="skill-percent d-none"
                >{{ technologie.percent }}%</span
              >
            </div>
            <span class="skillData-Wrapper">
              <span
                [ngClass]="technologie.remark"
                class="skillData bg-rust"
              ></span>
            </span>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4"
        data-aos="fade-down"
        data-aos-anchor-placement="center-bottom"
        data-aos-delay="300"
      >
        <h5 class="h5">
          <strong>{{ "skills.tools" | translate }}</strong>
        </h5>
        <div id="skillgraph" class="panel panel-default">
          <div class="row skill-row" *ngFor="let tool of skills.tools">
            <div class="label d-flex flex-row">
              <span class="skillLabel">{{ tool.name }}</span>
              <span class="skill-percent d-none">{{ tool.percent }}%</span>
            </div>
            <span class="skillData-Wrapper">
              <span [ngClass]="tool.remark" class="skillData bg-rust"></span>
            </span>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
        data-aos-delay="300"
      >
        <h5 class="h5">
          <strong>{{ "skills.methodologies" | translate }}</strong>
        </h5>
        <div id="skillgraph" class="panel panel-default">
          <div
            class="row skill-row"
            *ngFor="let methodologie of skills.methodologies"
          >
            <div class="label d-flex flex-row">
              <span class="skillLabel">{{ methodologie.name }}</span>
              <span class="skill-percent d-none"
                >{{ methodologie.percent }}%</span
              >
            </div>
            <span class="skillData-Wrapper">
              <span
                [ngClass]="methodologie.remark"
                class="skillData bg-rust"
              ></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

export const experiencesFr = [
  {
    title: 'Global Cybersecurity Threat Lead',
    company: 'Vodafone Intelligent Solutions',
    icon: 'vodafone.png',
    size: 18,
    period: '2024 - Current',
    technologies: [
      'SIEM',
      'Cyber As A Service',
      'Sec Ops',
      'Threat Intelligence',
    ],
    missions: [],
    detailIsDisplayed: false,
  },
  {
    title: 'IT Project Manager',
    company: 'Vodafone, Spain',
    icon: 'vodafone.png',
    size: 18,
    period: '2015 - Current',
    technologies: [
      'Software Architecture',
      'Security Operations',
      'Python, Angular',
      'Teradata, Oracle',
      'Project Management',
    ],
    missions: [
      '⌨️Development, evolution and software life cycle of different systems and products internally developed that are active in multiple Vodafone services',
      '💼Design of the departments software architecture, ensuring the robustness of new tools and strategic product orientation.',
      '🛡Improvement of the technical stack in the context of efficiency and cybersecurity in the infrastructure shielded ecosystems with IDS, SIEM, Hardening, Backup System and network filtering.',
    ],
    detailIsDisplayed: false,
  },
  {
    title: 'Content Creator',
    company: 'stackoverknow (30K followers)',
    icon: 'branding_logo.png',
    size: 26,
    period: 'Current',
    technologies: ['As far as the imagination goes'],
    missions: [
      'Serve my technical background to help other technical people in social networks (~30K followers).',
    ],
    detailIsDisplayed: false,
  },
  {
    title: 'Software Developer',
    company: 'Accenture > Banco Santander, Spain',
    icon: 'accenture.png',
    size: 18,
    period: '2013 - 2015',
    technologies: ['Cobol', 'JCL', 'DB2'],
    missions: [
      'Development in COBOL and JCL processes implementing functionality of SEPA system for Santander bank',
    ],
    detailIsDisplayed: false,
  },
];

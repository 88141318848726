<div class="page-content">
  <!--<app-header></app-header>-->
  <app-home></app-home>
  <app-about></app-about>
  <app-skills></app-skills>
  <app-curriculum-vitae></app-curriculum-vitae>
  <app-projects></app-projects>
  <app-references></app-references>
  <!--<app-contact></app-contact>-->
  <div style="padding-bottom: 50px"></div>
  <footer class="footer shadow">
    <p><a href="mailto:hello@oscarZapata.dev">hello@oscarZapata.dev</a></p>
  </footer>
</div>

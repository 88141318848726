import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import{Router, NavigationEnd} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  currentYear:number;
  constructor(private _translateService: TranslateService, public router: Router, private meta: Meta) {
    this._translateService.addLangs(['en', 'fr']);
    this._translateService.setDefaultLang('fr');
    this.currentYear=new Date().getFullYear();
    this.meta.addTag({ name: 'description', content: 'Web Developer Freelance - Custom Web Design & Development Services' });
    this.meta.addTags([ 
      { name: 'description', content: 'Web Developer Freelance - Custom Web Design & Development Services' }, 
      { name: 'keywords', content: ', desarrollador web, desarrollador web freelance, servicios de diseño web, desarrollo web personalizado, desarrollo de comercio electrónico, mantenimiento de sitios web, codificación HTML/CSS, diseño multiplataforma, desarrollo de WordPress, programación web, desarrollo front-end, desarrollo back-end, aplicación web desarrollo, optimización SEO' } 
  ]);
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
          gtag('config', 'G-MWT47HCBYV', {'page_path': event.urlAfterRedirects});
      }
    })
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", 'en');
    }
    let lang:any=localStorage.getItem("lang"); 
    this._translateService.setDefaultLang(lang);
    this._translateService.use(lang);
  }

}

export const projectsFr = [
  {
    id: 'raspberry',
    name: 'Raspbery: Multiple use cases',
    technologies: 'Linux, Bash, Networking, Hosting',
    images: [
      {
        title: 'website',
        src: 'assets/images/raspberry.png',
      },
    ],
    url: 'https://www.raspberrypi.org//',
    detail:
      'My raspberry is my personal cloud access point to cook my experiments. Throughout her life, she has served Kali Linux OS, Web server, MySQL Server, VPN, NAS...',
    codeSourceUrl: 'https://www.raspberrypi.org/',
    detailIsDisplayed: true,
  },
  {
    id: 'lawyerPage',
    name: 'Independent Law Firm',
    technologies: 'Angular, Python, Google Cloud',
    images: [
      {
        title: 'website',
        src: 'assets/images/lawyerPage_1.png',
      },
      {
        title: 'tiger NFT',
        src: 'assets/images/lawyerPage_2.png',
      },
    ],
    url: 'https://sonia-lawyer.web.app/',
    detail:
      'End-to-End Branding, Design, and Solution to show the services provided for a law firm, engage the customers and make the best conversion. This page is a part of the marketing package I provide accompained by Google Ads, Analytics and full automated deployment through Google Cloud Platform',
    codeSourceUrl: 'https://sonia-lawyer.web.app',
    detailIsDisplayed: false,
  },
  {
    defaultBotText:
      'Right now Oscar is working with the API and training his bot  to get the best AI about himself',
    id: 'ethTigers',
    name: 'ETH Tigers NFT',
    technologies: 'React, Web3, Solidity (ETH network)',
    images: [
      {
        title: 'website',
        src: 'assets/images/ethtigers.webp',
      },
      {
        title: 'tiger NFT',
        src: 'assets/images/tiger_a.webp',
      },
    ],
    url: 'https://twitter.com/ethtigers',
    detail:
      'Owner of ETH Tigers experiment: A exclusive NFT collection of 1.000 tigers made with the computer mouse freehand',
    codeSourceUrl: 'https://twitter.com/ethtigers',
    detailIsDisplayed: false,
  },
  {
    id: 'neonjett',
    name: 'Neon Jett',
    technologies: 'HTML, JS, Wordpress',
    images: [
      {
        title: 'Neon Jett site',
        src: 'assets/images/neonjett.webp',
      },
      {
        title: 'Galery',
        src: 'assets/images/neonjett_galery.webp',
      },
    ],
    url: 'https://www.neonjett.com',
    detail:
      '<p>Website to offer customized led neon production services and accompaniment in digital marketing to boost your business</p>',
    codeSourceUrl: 'https://neonjett.com',
    detailIsDisplayed: false,
  },
  {
    id: 'smartKitchen',
    name: 'Smart Kitchen',
    technologies: 'Ionic (Hybrid Apps), React, Python (django rest)',
    images: [
      {
        title: 'Smart Kitchen',
        src: 'assets/images/smart_kitchen.png',
      },
    ],
    url: '#portfolio',
    detail:
      'App and large database with recipes (scrapped from different origins) where people can type their ingredients and the API will return the different possible recipes.',
    codeSourceUrl: 'https://oscarZapata.dev',
    detailIsDisplayed: false,
  },
];

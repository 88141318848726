import { Component, OnInit } from '@angular/core';
import { TranslationLoaderService } from '../service/translation-loader.service';
import { locale as english } from '../shared/i18n/en';
import { locale as french } from '../shared/i18n/fr';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css'],
})
export class SkillsComponent implements OnInit {
  skills: any = {
    technologies: [
      { name: 'Python, Bash', percent: '80', remark: 'excellent' },
      { name: 'Database, ETL, Procedures', percent: 90, remark: 'very-good' },
      { name: 'Angular, React, JavaScript', percent: 70, remark: 'very-good' },
    ],
    tools: [
      {
        name: 'Firewall, SIEM, OS hardening, IDS, IPS',
        percent: 90,
        remark: 'excellent',
      },
      { name: 'Git, GitLab, Github', percent: 90, remark: 'excellent' },
      { name: 'vSphere, Cloud, VM Ware', percent: 70, remark: 'excellent' },
      { name: 'Docker, Kubernetes', percent: 50, remark: 'good' },
    ],
    methodologies: [
      {
        name: 'Risk Management, Security Assesment, Vulnerabilities',
        percent: 70,
        remark: 'good',
      },
      { name: 'Design Thinking, Agile', percent: 70, remark: 'very-good' },
      {
        name: 'DevSecOps, Zero Day patching',
        percent: 50,
        remark: 'very-good',
      },
    ],
    devTags: [
      'python',
      'bash',
      'software operations',
      'angular',
      'html',
      'javascript',
      'database',
    ],
    secTags: ['siem', 'ids', 'git', 'firewall', 'hardening'],
    managementTags: [
      'risk management',
      'software architecture',
      'security operations',
    ],
  };

  constructor(private _translationLoaderService: TranslationLoaderService) {
    this._translationLoaderService.loadTranslations(english, french);
  }

  ngOnInit(): void {}
}

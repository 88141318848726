export const referencesFr = [
    {
        personImg: "assets/images/javier-morejon.jpg",
        personFullname: 'Javier Morejon',
        personPosition: "Founder",
        personCompany: 'Magnus Strategy Group',
        linkedIn: 'https://www.linkedin.com/in/javier-morej%C3%B3n-tejedor-98156b61/',
        urlCompany: 'https://magnus-strategy.com/',
        message: "I highly recommend Oscar for any digital solution and software architecture position. He possess exceptional leadership skills, technical expertise, and a track record of delivering high-quality solutions that align with organizational goals.",
    },
    {
        personImg: "assets/images/antonio-andrade.jpg",
        personFullname: 'Antonio Andrade',
        personPosition: "Team Leader",
        personCompany: 'Vodafone',
        linkedIn: 'https://www.linkedin.com/in/antonioandrademacho/',
        urlCompany: 'https://vodafone.com/',
        message: "Oscar is an accomplished Solution Architect with exceptional software management skills. His visionary approach to designing and implementing software solutions has consistently impressed colleagues and clients. With a passion for innovation and a talent for team coordination, Oscar consistently delivers high-quality results that drive organizational success.",
    },
    {
        personImg: "assets/images/pablo-rodriguez.jpg",
        personFullname: 'Pablo Rodriguez',
        personPosition: "Digital Marketing",
        personCompany: 'Go To Market',
        linkedIn: 'https://www.linkedin.com/in/pablo-rodriguez-fuente/',
        urlCompany: 'https://gotomarket.marketing/',
        message: "Oscar is an exceptional Digital Solutions Expert with a keen entrepreneurial spirit and outstanding software management skills. Collaborating with him, I have been consistently impressed by his innovative approach to designing and implementing digital solutions. His expertise, dedication, and ability to coordinate teams make him an invaluable asset for any business venture.",
    }
];


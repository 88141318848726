<section id="about">
  <div class="container">
    <h2>
      <strong>{{ "about.title" | translate }}</strong>
    </h2>
    <span class="bar-title mb-5"></span>
    <div class="row">
      <div class="col-lg-4 col-md-12 text-center" data-aos="fade-up">
        <img
          height="300px"
          src="/assets/images/yo.jpg"
          alt=""
          srcset=""
          style="border-radius: 50%"
        />
        <div class="d-flex justify-content-center">
          <a
            class="btn-social-media"
            href="https://www.tiktok.com/@stackoverknow/"
            rel="tooltip"
            target="_blank"
            title="{{ 'about.followMeOn' | translate }} Linkedin"
          >
            <i style="line-height: 1">Tik<br />Tok</i>
          </a>
          <a
            class="btn-social-media"
            href="https://www.instagram.com/stackoverknow/"
            rel="tooltip"
            target="_blank"
            title="{{ 'about.followMeOn' | translate }} Linkedin"
          >
            <i class="fa fa-instagram fa-lg"></i>
          </a>
          <a
            class="btn-social-media"
            href="https://www.youtube.com/@stackoverknow/"
            rel="tooltip"
            target="_blank"
            title="{{ 'about.followMeOn' | translate }} Linkedin"
          >
            <i class="fa fa-youtube fa-lg"></i>
          </a>
          <a
            class="btn-social-media"
            href="https://www.linkedin.com/in/oscar-Zapata-fuente/"
            rel="tooltip"
            target="_blank"
            title="{{ 'about.followMeOn' | translate }} Linkedin"
          >
            <i class="fa fa-linkedin fa-lg"></i>
          </a>
        </div>
      </div>
      <div class="col-lg-8 col-md-12 pt-4 pt-lg-0" data-aos="fade-up">
        <h3 class="text-center">{{ "about.jobTitle" | translate }}</h3>
        <p class="font-italic text-center">
          {{ "about.introductionOfMe" | translate }}
        </p>
        <div class="text-center">
          <a
            target="_blank"
            href="https://www.credly.com/badges/d645fadf-d72e-4596-87d2-63244302e27b/public_url"
          >
            <h5>Cybersecurity Certified <fa-icon [icon]="faLink"></fa-icon></h5
          ></a>
          <img width="140" src="/assets/images/CISSP.png" alt="" srcset="" />
          <img
            width="140"
            src="/assets/images/certified-in-cybersecurity-cc.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <ul>
              <li>
                <fa-icon [icon]="mapMarker"></fa-icon
                ><strong> {{ "about.mobility" | translate }}</strong>
              </li>
              <li>
                <fa-icon [icon]="building"></fa-icon
                ><strong> Vodafone Intelligence Solutions</strong>
              </li>
              <li>
                <fa-icon [icon]="email"></fa-icon
                ><strong
                  ><a href="mailto:hello@oscarZapata.dev">
                    hello@oscarZapata.dev</a
                  ></strong
                >
              </li>
            </ul>
          </div>
          <div class="col-lg-6 col-md-6">
            <ul>
              <li>
                <fa-icon [icon]="birthday"></fa-icon
                ><strong> {{ myYears }} {{ "about.years" | translate }}</strong>
              </li>
              <li>
                <fa-icon [icon]="badge"></fa-icon
                ><strong> {{ "about.graduation" | translate }}</strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section
  id="home"
  [ngStyle]="{ backgroundImage: 'url(assets/images/home-background.jpg)' }"
  class="d-flex flex-column justify-content-center align-items-center"
>
  <div class="hero-container aos-init aos-animate" data-aos="fade-in">
    <h1 class="mt-3">Oscar Zapata</h1>
    <p>{{ "home.iAmDeveloper" | translate }} <span class="typed"></span></p>
  </div>
</section>

<section id="contact">
    <div class="container mb-5">
        <h2><strong >{{'contact.contactMe' | translate}}</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row"  data-aos="fade-up"
        data-aos-anchor-placement="top-center">
            <div class="col-md-3 col-lg-3">
                <div class="contact-info">
                    <img src="assets/images/contact-image.png" alt="image" />
                    <h4>{{'contact.notHesitateToContactMe' | translate}}</h4>
                </div>
            </div>
            <div class="col-md-9">
                <div class="contact-form">
                    <div class="form-group">
                        <label class="control-label col-sm-2" for="identite">{{'contact.identify' | translate}}</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="identite" placeholder="{{'contact.enterIdentify' | translate}}"
                                name="fname">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-sm-2" for="email">{{'contact.email' | translate}}</label>
                        <div class="col-sm-10">
                            <input type="email" class="form-control" id="email" placeholder="{{'contact.enterEmail' | translate}}" name="email">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-sm-2" for="object">{{'contact.object' | translate}}</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="object" placeholder="{{'contact.enterObject' | translate}}"
                                name="object">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-sm-2" for="comment">{{'contact.message' | translate}}</label>
                        <div class="col-sm-10">
                            <textarea class="form-control" rows="5" id="comment"></textarea>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-offset-2 col-sm-10">
                            <button type="submit" class="btn btn-default">{{'contact.send' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
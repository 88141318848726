<section id="cv">
  <div class="container">
    <h2>
      <strong> {{ "education.title" | translate }}</strong>
    </h2>
    <span class="bar-title mb-5"></span>
    <div class="row">
      <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
        <h4>
          <strong>{{ "education.eductionTitle" | translate }}</strong>
        </h4>
        <ul class="timeline">
          <li>
            <h5>{{ "education.cissp" | translate }}</h5>
            <em class="font-italic">{{
              "education.certification" | translate
            }}</em>
            <p
              class="year p-2 d-flex justify-content-center vf-style-secondary"
            >
              2024
            </p>
          </li>
          <li>
            <h5>{{ "education.cc" | translate }}</h5>
            <em class="font-italic">{{
              "education.certification" | translate
            }}</em>
            <p
              class="year p-2 d-flex justify-content-center vf-style-secondary"
            >
              2024
            </p>
          </li>
          <li>
            <h5>{{ "education.dam" | translate }}</h5>
            <em class="font-italic">{{
              "education.university" | translate
            }}</em>
            <p
              class="year p-2 d-flex justify-content-center vf-style-secondary"
            >
              2012 - 2014
            </p>
          </li>
          <li>
            <h5>{{ "education.smr" | translate }}</h5>
            <em class="font-italic">{{ "education.school" | translate }}</em>
            <p
              class="year p-2 d-flex justify-content-center vf-style-secondary"
            >
              2010 - 2012
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
        <h4>
          <strong>{{ "education.experiencesTitle" | translate }}</strong>
        </h4>
        <ul class="timeline">
          <li *ngFor="let experience of experiences">
            <h5>{{ experience.title }}</h5>

            <em
              ><img
                src="assets/images/{{ experience.icon }}"
                width="{{ experience.size }}"
              />{{ experience.company }}</em
            >
            <div class="d-flex flex-row">
              <p
                class="year p-2 d-flex justify-content-center vf-style-secondary"
              >
                {{ experience.period }}
              </p>
              <button
                (click)="detailOnClick(experience)"
                type="button"
                class="btn btn-primary"
              >
                <span *ngIf="!experience.detailIsDisplayed">+</span
                ><span *ngIf="experience.detailIsDisplayed">-</span>
              </button>
            </div>
            <p *ngIf="experience.detailIsDisplayed">
              <span
                *ngFor="
                  let technology of experience.technologies;
                  let index = index
                "
                class="m-1 badge-info badge"
              >
                {{ technology }} </span
              ><br />
              <span
                *ngFor="let mission of experience.missions; let index = index"
              >
                <strong>- </strong>{{ mission }} <br />
              </span>
            </p>
          </li>
        </ul>
      </div>
      <div
        class="col-lg-4 col-md-12 aos-init aos-animate"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <div class="container row">
          <div
            class="aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <h4>
              <strong>{{ "education.languages" | translate }}</strong>
            </h4>
            <div class="noProgress langue">
              <span class=""
                ><img
                  src="assets/images/spanish-flag.png"
                  width="20"
                  alt="spain"
                /><strong> {{ "education.spanish" | translate }}</strong>
                <i class="val"> {{ "education.native" | translate }}</i></span
              >
            </div>
            <div class="noProgress langue">
              <span class=""
                ><img
                  src="assets/images/united-kingdom-flag.png"
                  width="20"
                  alt="english"
                /><strong> {{ "education.english" | translate }}</strong>
                <i class="val">
                  {{ "education.goodLevel" | translate }}</i
                ></span
              >
            </div>
          </div>
        </div>
        <div class="container row">
          <div
            class="mt-3 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <h4>
              <strong>{{ "education.centerOfInterest" | translate }}</strong>
            </h4>
            <div class="noProgress langue">
              <span>
                <fa-icon [icon]="planeIcon"></fa-icon
                ><strong> {{ "education.trips" | translate }}</strong>
                <i class="val">
                  {{ "education.tripsCountries" | translate }}
                </i>
              </span>
            </div>
            <div class="noProgress langue">
              <span>
                <fa-icon [icon]="faChartLine"></fa-icon
                ><strong> {{ "education.investments" | translate }} </strong>
                <i class="val"> {{ "education.stocksMarket" | translate }} </i>
              </span>
            </div>
            <div class="noProgress langue">
              <span>
                <fa-icon [icon]="gameIcon"></fa-icon
                ><strong> {{ "education.boardGames" | translate }} </strong>
                <i class="val">
                  {{ "education.boardGamesPlayed" | translate }}
                </i>
              </span>
            </div>
            <div class="noProgress langue">
              <span>
                <fa-icon [icon]="faBeer"></fa-icon
                ><strong> {{ "education.friends" | translate }} </strong>
                <i class="val"> {{ "education.hangingOut" | translate }} </i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

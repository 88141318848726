export const locale = {
  lang: 'en',
  data: {
    about: {
      title: 'About',
      jobTitle: 'Global Cybersecurity Threat Lead',
      introductionOfMe:
        'During these 10 years in software development, I have learned from different people, teams and methodologies. I value this because in addition to technical experience, I believe that being able to take care of these bases adds up to leveraging efficiency and reaching corporate-level objectives.',
      passionateAboutDevelopment: 'Passionate about development',
      mobility: 'Madrid, Spain',
      years: 'Years',
      graduation: 'DAM & SMR',
      myResearch: 'Improve business throught technology',
      researchDescription:
        'I am looking for an innovative and user-centric company to best meet the needs customer, from his idea to the final delivery of his project, or participate in the improvement of a project existing.',
      and: 'and',
      followMeOn: 'Follow me on',
    },
    contact: {
      contactMe: 'Contact Me',
      notHesitateToContactMe: 'Do not hesitate to contact me',
      identify: 'Identify',
      enterIdentify: 'Enter your identify',
      email: 'Email',
      enterEmail: 'Enter your email',
      object: 'Object',
      enterObject: 'Enter object',
      message: 'Message',
      send: 'Send',
    },
    education: {
      title: 'Curriculum Vitae',
      eductionTitle: 'Education',
      experiencesTitle: 'Experiences',
      centerOfInterest: 'Center of interest',
      sport: 'Sport',
      trips: 'Trips',
      boardGames: 'Gaming',
      languages: 'Languages',
      native: 'Native',
      goodLevel: 'B2',
      spanish: 'Spanish',
      english: 'English',
      school: 'Sta M. de los Apostoles',
      cissp: 'CISSP',
      gcp: 'Google Associate Cloud Engineer',
      remote: 'Remote',
      wip: 'In progress',
      smr: 'Microcomputer systems and networks',
      daw: 'Web applications development',
      cc: 'CC',
      dam: 'Cross platform application development',
      masterDegreeTwoDesc: 'Master of degital services',
      masterDegreeOne: "Master's degree 1 IT",
      masterDegreeOneDesc: 'General Master Computer Science',
      bachlorDegree: "Bachelor's degree",
      bachlorDegreeDesc: 'Bachlor of Computer Science',
      university: 'Clara del Rey, Madrid',
      certification: 'ISC2',
      mission: 'Mission',
      technologies: 'Technologies',
      detail: 'Detail',
      tripsCountries:
        'Germany, Belgium, London, Morocco, Italy, Greece, Portugal, Netherland',
      boardGamesPlayed: 'Valorant, LoL, RPG',
      years: 'years',
      friends: 'Networking',
      hangingOut: 'Hanging out with my friends',
      investments: 'Investments',
      stocksMarket: 'Stock market investments',
    },
    header: {
      home: 'Home',
      about: 'About',
      skills: 'Skills',
      references: 'References',
      contactMe: 'Contact me',
    },
    home: {
      iAmDeveloper: 'I am',
    },
    projects: {
      title: 'Portfolio',
      projectDetail: 'Project detail',
      sourceCode: 'Source code',
    },
    references: {
      title: 'References',
    },
    skills: {
      title: 'Skills',
      technologies: 'Technologies',
      tools: 'Tools',
      methodologies: 'Methodologies',
    },
    defaultBotText:
      'Right now Oscar is working with the API and training his bot  to get the best AI about himself',
  },
};
